<template>
  <div class="contain">
    <div class="page_title_contain">
      <header class="other_page_head">
        <div class="common_warp header_wrap">
          <div
            class="icon"
            @click="
              () => {
                $router.push('/home')
              }
            "
          >
            <img src="../assets/eamaster_logo_title_index.png" alt="" />
          </div>
          <div class="menu index_page_menu">
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/more')
                }
              "
            >
              全部活动
            </p>
            <p
              class="menu_item"
              @click="
                () => {
                  $router.push('/about')
                }
              "
            >
              关于我们
            </p>
            <a-input-search
              class="input_search"
              placeholder="夏校、机器人、耶鲁、纽约…"
              enter-button="搜索"
              size="large"
              :maxLength="100"
              v-model="keyword"
              @search="onSearch"
            >
              <a-icon
                slot="prefix"
                type="search"
                :style="{ color: '#cdcdcd' }"
              />
            </a-input-search>
          </div>
          <div class="language other_page_language">
            <span class="pointer">中文</span> <span class="line">|</span
            ><span
              class="pointer"
              @click="
                () => {
                  $router.push('english')
                }
              "
              >English</span
            >
          </div>
        </div>
      </header>
    </div>
    <header class="common_warp detail_header">
      <div class="title">{{ info.name }}</div>
      <div class="tag">
        <span
          :style="'background:' + item.colorHex"
          v-for="item in info.symbol"
          :key="item.id"
        >
          {{ item.name }}
        </span>
      </div>
    </header>
    <main class="common_warp">
      <div class="content">
        <img
          class="frontPicture"
          :src="info.frontPicture && info.frontPicture.url"
          alt=""
        />
        <div class="detail_title">活动详情</div>
        <div class="page_content_active_info">
          <Editor
            v-model="html"
            :defaultConfig="editorConfig"
            mode="simple"
            @onCreated="onCreated"
          />
        </div>
      </div>
      <div class="label_box">
        <div class="label">
          <div class="lab_title">活动时间:</div>
          <div class="lab_text">
            {{ new Date(info.activeStartTime).Format('yyyy.MM.dd') }} -
            {{ new Date(info.activeEndTime).Format('yyyy.MM.dd') }}
          </div>
          <div class="lab_title">报名时间:</div>
          <div class="lab_text">
            {{ new Date(info.registrationStartTime).Format('yyyy.MM.dd') }} -
            {{ new Date(info.registrationEndTime).Format('yyyy.MM.dd') }}
          </div>
          <template v-for="item in info.labelGroup">
            <div :key="item.id + '1'" class="lab_title">{{ item.name }}</div>
            <div :key="item.id + '2'" class="lab_text">
              {{
                item.label
                  .map((e) => {
                    return e.name
                  })
                  .join('、')
              }}
            </div>
          </template>
        </div>
        <div class="contact">
          <div class="cont_title">立即咨询</div>
          <img src="@/assets/pc_weixin.png" alt="" class="pc_wx" />
          <div class="little_title">添加小助手的微信</div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import { Editor } from '@wangeditor/editor-for-vue'
import { activeDetail } from '@/api/home'
import util from '@/utils/utils'
export default {
  data() {
    return {
      info: {},
      keyword: '',
      editor: null,
      html: '',
      editorConfig: { readOnly: true },
    }
  },
  components: { Editor },
  created() {
    this.getInfo()
  },
  // 详情页面的beforeRouteLeave钩子函数
  beforeRouteLeave(to, from, next) {
    if (to.name == 'more') {
      to.meta.isUseCache = true
    }
    next()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    async getInfo() {
      const { data } = await activeDetail({ id: this.$route.query.id })
      if (data) {
        this.info = data
        this.html = data.detail
      } else {
        this.$router.push('404')
      }
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    onSearch() {
      this.$router.push({
        params: {
          keyword: this.keyword,
        },
        name: 'more',
      })
    },
  },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="less" scoped>
.contain {
  .page_title_contain {
    background-color: #fff;

    .common_warp,
    .slogan_image {
      position: relative;
      z-index: 1;
    }

    header .slogan_wrap {
      position: absolute;
      z-index: 0;
    }

    header .menu .menu_item {
      color: #333333;
    }
  }

  .detail_header {
    width: 100%;
    min-height: 206px;
    background: #f1f2f0;
    text-align: center;
    padding-top: 60px;
    margin-bottom: 32px;

    .title {
      font-size: 36px;
      font-family: AlibabaPuHuiTiM;
      color: #333333;
      line-height: 50px;
      margin-bottom: 12px;
    }

    .tag {
      display: flex;
      justify-content: center;

      span {
        margin-right: 28px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 24px;
        width: 78px;
        height: 24px;
        border-radius: 2px;

        &:last-child {
          margin: 0;
        }
      }
    }
  }

  main {
    display: flex;

    .content {
      min-width: unset;
      flex: 1;
      margin-bottom: 100px;

      .frontPicture {
        margin-bottom: 32px;
      }

      .detail_title {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #333333;
        line-height: 27px;
        margin-bottom: 16px;
      }

      /deep/ img {
        width: 100%;
        object-fit: contain;
      }
    }

    .label_box {
      margin-left: 90px;
    }

    .label {
      width: 276px;
      padding: 20px 20px 1px;
      background: #ffffff;
      margin-bottom: 24px;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);

      .lab_title {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #333333;
        line-height: 30px;
        margin-bottom: 8px;
      }

      .lab_text {
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: #333333;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }

    .contact {
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.06);
      padding-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;

      .cont_title {
        font-size: 20px;
        font-family: AlibabaPuHuiTiM;
        color: #333333;
        line-height: 30px;
        margin-bottom: 12px;
      }

      .pc_wx {
        width: 168px;
        height: 168px;
        margin-bottom: 12px;
      }

      .little_title {
        font-size: 16px;
        font-family: AlibabaPuHuiTiR;
        color: #333333;
        line-height: 22px;
      }
    }
  }
}

.page_content_active_info {
  > p {
    line-height: 24px;
  }

  > p + p {
    margin-top: 24px;
  }
}
</style>
