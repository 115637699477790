var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contain"},[_c('div',{staticClass:"page_title_contain"},[_c('header',{staticClass:"other_page_head"},[_c('div',{staticClass:"common_warp header_wrap"},[_c('div',{staticClass:"icon",on:{"click":() => {
              _vm.$router.push('/home')
            }}},[_c('img',{attrs:{"src":require("../assets/eamaster_logo_title_index.png"),"alt":""}})]),_c('div',{staticClass:"menu index_page_menu"},[_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/more')
              }}},[_vm._v(" 全部活动 ")]),_c('p',{staticClass:"menu_item",on:{"click":() => {
                _vm.$router.push('/about')
              }}},[_vm._v(" 关于我们 ")]),_c('a-input-search',{staticClass:"input_search",attrs:{"placeholder":"夏校、机器人、耶鲁、纽约…","enter-button":"搜索","size":"large","maxLength":100},on:{"search":_vm.onSearch},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}},[_c('a-icon',{style:({ color: '#cdcdcd' }),attrs:{"slot":"prefix","type":"search"},slot:"prefix"})],1)],1),_c('div',{staticClass:"language other_page_language"},[_c('span',{staticClass:"pointer"},[_vm._v("中文")]),_vm._v(" "),_c('span',{staticClass:"line"},[_vm._v("|")]),_c('span',{staticClass:"pointer",on:{"click":() => {
                _vm.$router.push('english')
              }}},[_vm._v("English")])])])])]),_c('header',{staticClass:"common_warp detail_header"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.info.name))]),_c('div',{staticClass:"tag"},_vm._l((_vm.info.symbol),function(item){return _c('span',{key:item.id,style:('background:' + item.colorHex)},[_vm._v(" "+_vm._s(item.name)+" ")])}),0)]),_c('main',{staticClass:"common_warp"},[_c('div',{staticClass:"content"},[_c('img',{staticClass:"frontPicture",attrs:{"src":_vm.info.frontPicture && _vm.info.frontPicture.url,"alt":""}}),_c('div',{staticClass:"detail_title"},[_vm._v("活动详情")]),_c('div',{staticClass:"page_content_active_info"},[_c('Editor',{attrs:{"defaultConfig":_vm.editorConfig,"mode":"simple"},on:{"onCreated":_vm.onCreated},model:{value:(_vm.html),callback:function ($$v) {_vm.html=$$v},expression:"html"}})],1)]),_c('div',{staticClass:"label_box"},[_c('div',{staticClass:"label"},[_c('div',{staticClass:"lab_title"},[_vm._v("活动时间:")]),_c('div',{staticClass:"lab_text"},[_vm._v(" "+_vm._s(new Date(_vm.info.activeStartTime).Format('yyyy.MM.dd'))+" - "+_vm._s(new Date(_vm.info.activeEndTime).Format('yyyy.MM.dd'))+" ")]),_c('div',{staticClass:"lab_title"},[_vm._v("报名时间:")]),_c('div',{staticClass:"lab_text"},[_vm._v(" "+_vm._s(new Date(_vm.info.registrationStartTime).Format('yyyy.MM.dd'))+" - "+_vm._s(new Date(_vm.info.registrationEndTime).Format('yyyy.MM.dd'))+" ")]),_vm._l((_vm.info.labelGroup),function(item){return [_c('div',{key:item.id + '1',staticClass:"lab_title"},[_vm._v(_vm._s(item.name))]),_c('div',{key:item.id + '2',staticClass:"lab_text"},[_vm._v(" "+_vm._s(item.label .map((e) => { return e.name }) .join('、'))+" ")])]})],2),_vm._m(0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contact"},[_c('div',{staticClass:"cont_title"},[_vm._v("立即咨询")]),_c('img',{staticClass:"pc_wx",attrs:{"src":require("@/assets/pc_weixin.png"),"alt":""}}),_c('div',{staticClass:"little_title"},[_vm._v("添加小助手的微信")])])
}]

export { render, staticRenderFns }